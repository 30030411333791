import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Together = () => {
  const data = useStaticQuery(graphql`
    query {
      privacy: file(relativePath: { eq: "Together.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <div className="mx-auto mb-4">
      <Img fluid={data.privacy.childImageSharp.fluid} />
    </div>
  )
}

export default Together