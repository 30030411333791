import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Together from "../components/Together"

const ParentsAndCarersPage = () => (
  <Layout>
    <SEO title="Parents and Carers" />
    <Container className="my-4">
      <h1 className="text-primary">Parents and Carers</h1>
      <h3 className="text-secondary">Individual Assessments of Special Educational Needs</h3>
      <p>
        Unfortunately, we are not able to take parent or carer referrals for one-off individual assessments of their child’s special educational needs outside of an educational context. This is because experience has taught us that the applications of psychology best support outcomes for children and young people when they are applied directly in collaboration with their teachers. This enables a psychologist to engage in a consultation process with school staff to develop a shared understanding of a child or young person’s needs and, thereafter, to agree solutions within an action plan of support.
      </p>
      <Row>
        <Col xl={{span: 4, offset: 0, order: 'first'}} lg={{span: 5, offset: 0, order: 'first'}} xs={{span: 8, offset: 2, order: 'first'}} className="pr-lg-5">
          <Together/>
        </Col>
        <Col xl={8} lg={7}>
          <p>
            Parental or carer engagement throughout an educational psychologist’s assessment is, however, essential to the success of our involvement. Conversations with parents or carers are held to gather information, and we work collaboratively within the consultation problem-solving process between home and school (wherever possible). All agreed actions and reports are shared with parents or carers, together with further invitations for involvement when interventions are reviewed. Educational psychologists also only become involved when informed parent or carer consent (signed) is provided.
          </p>
          <p>
            If you are worried about the identification of your child’s special educational needs, their learning progress and/or their access to interventions, we recommend you talk to the Special Educational Needs and Disability Co-ordinator (SENDCo) within their school, or seek further advice from your local authority’s Special Educational Needs and Disability Information Advice and Support service (SENDIAS).
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ParentsAndCarersPage
